<template>
  <v-card class="popup-card">
    <v-card-title v-if="popup.title" class="popup-title">{{
      popup.title
    }}</v-card-title>
    <v-card-text
      ><v-row
        v-for="list in popup.lists"
        :key="list.id"
        outlined
        :list="list"
        @click="selectList(list)"
      >
        <v-col>{{ list.name }}</v-col>
      </v-row></v-card-text
    >
    <v-card-actions class="justify-end">
      <v-btn color="primary" depressed min-width="150" @click="submit()"
        >Annulla</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<style lang="scss">
.v-dialog.vuedl-layout {
  .popup-card.v-card {
    .v-card__text {
      padding-top: 10px !important;
    }
  }
}
.popup-card {
  .popup-title {
    font-size: 20px;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import SelfscanningService from "~/service/selfscanningService";

export default {
  name: "SelfscanningListSelector",
  props: {
    popup: { type: Object, required: true },
    categoryId: { type: Number, required: true }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      setPopupMap: "app/setPopupMap"
    }),
    submit() {
      this.setPopupMap(this.categoryId + "" + this.popup.TITLE);
      this.$emit("submit", true);
    },
    async selectList(list) {
      let cloneList = await SelfscanningService.cloneFromList(list.id);
      this.$emit("submit", true);
      if (cloneList) {
        global.EventBus.$emit("updateSelfCart", cloneList.data);
      }
    }
  }
};
</script>
