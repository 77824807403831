<template>
  <div class="stepper stepper-selfscanning quantity-box">
    <div class="stp" :class="{ 'manual-input': inset.showInput }">
      <div class="quantity-container">
        <div
          class="qty quantity-value d-flex flex-row"
          v-if="inset.showInput === false"
          :class="{ 'can-edit': canChangeMode() }"
        >
          <div
            class="edit"
            @click.stop.prevent="changeMode()"
            v-if="initQuantity != 0"
          >
            <span>
              {{ initQuantity }} pz
              <span class="smaller" v-if="initQuantityPerUnit != ''"
                >({{ initQuantityPerUnit }})</span
              >
            </span>
            <v-icon class="ml-2" @click.prevent.stop="changeMode(true)"
              >$edit</v-icon
            >
          </div>
          <div class="qty-empty" v-if="initQuantity == 0">
            <span>--</span>
          </div>
        </div>
        <div class="qty d-flex flex-row" v-if="inset.showInput === true">
          <button
            @click.prevent.stop="changeMode(true)"
            class="button  button-clear button-energized"
          >
            <v-icon>$close</v-icon>
          </button>
          <input
            min="0"
            type="number"
            :id="'val-input-' + item.product.productId"
            v-model="inset.quantity"
          />
          <button
            @click.prevent.stop="confirmEdit('selfscanning')"
            class="button button-clear button-energized"
          >
            <v-icon>$success</v-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.stp {
  display: flex;
  .icon {
    color: $secondary;
  }
}
.quantity-container {
  display: flex;
  width: 100%;
  .qty.quantity-value {
    display: flex;
    justify-content: center;
    .edit {
      display: flex;
      margin-top: 4px;
    }
  }
  .qty {
    input {
      width: 100%;
      text-align: center;
    }
    width: 100%;
    display: inline-block;
    height: 28px;
    border: 1px solid #ddd;
    background-color: #e7e8e9;
    color: #002f65;
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
    overflow: hidden;
  }
}
</style>
<script>
import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import AnalyticsService from "~/service/analyticsService";
import SelfscanningService from "~/service/selfscanningService";
import ListService from "~/service/listService";
import LongPress from "vue-directive-long-press";

export default {
  name: "StepperSelfscanning",
  data() {
    return {
      inset: {
        showInput: false,
        quantity: 0,
        quantityPerUnit: 0
      }
    };
  },
  directives: {
    "long-press": LongPress
  },
  props: {
    item: { Type: Object, required: true }
  },
  computed: {
    hasCustomWeight(prod) {
      if (prod) {
        for (var j = 0; j < prod.selectOptions.length; j++) {
          var option = prod.selectOptions[j];
          if (option.parameterName == "CUSTOM_WEIGHT") {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },
    initQuantity() {
      let amount = null;
      var quantity = null;
      // let quantityPerUnit = null;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        amount = val;
      } else {
        amount = this.item.quantity;
      }
      quantity = amount;

      // if (
      //   this.item.product.productInfos.TIPOLOGIA != "Sfuso" &&
      //   this.item.product.logisticPalletItems > 0 &&
      //   amount % this.item.product.logisticPalletItems == 0 &&
      //   !this.hidePackaging
      // ) {
      //   quantityPerUnit =
      //     amount / this.item.product.logisticPalletItems + " pallet";
      // } else if (
      //   this.item.product.productInfos.TIPOLOGIA != "Sfuso" &&
      //   this.item.product.logisticPackagingItems > 0 &&
      //   amount % this.item.product.logisticPackagingItems == 0 &&
      //   !this.hidePackaging
      // ) {
      //   var value = amount / this.item.product.logisticPackagingItems;
      //   quantityPerUnit = "" + value + (value == 1 ? " imballo" : " imballi");
      // } else {
      //   quantityPerUnit = CatalogService.getItemPerUnitSuffix(
      //     this.item,
      //     amount
      //   );
      // }
      return quantity;
    },
    initQuantityPerUnit() {
      let amount = null;
      // let quantity = null;
      let quantityPerUnit = null;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        amount = val;
      } else {
        amount = this.item.quantity;
      }
      // quantity = amount;

      if (
        this.item.product.productInfos.TIPOLOGIA != "Sfuso" &&
        this.item.product.logisticPalletItems > 0 &&
        amount % this.item.product.logisticPalletItems == 0 &&
        !this.hidePackaging
      ) {
        quantityPerUnit =
          amount / this.item.product.logisticPalletItems + " pallet";
      } else if (
        this.item.product.productInfos.TIPOLOGIA != "Sfuso" &&
        this.item.product.logisticPackagingItems > 0 &&
        amount % this.item.product.logisticPackagingItems == 0 &&
        !this.hidePackaging
      ) {
        var value = amount / this.item.product.logisticPackagingItems;
        quantityPerUnit = "" + value + (value == 1 ? " imballo" : " imballi");
      } else {
        quantityPerUnit = CatalogService.getItemPerUnitSuffix(
          this.item,
          amount
        );
      }
      return quantityPerUnit;
    }
  },
  methods: {
    confirmEdit(type) {
      if (this.inset.quantity != null && this.inset.quantity != undefined) {
        this.inset.showInput = false;
        if (!type) {
          CartService.setItem(this.item, this.inset.quantity).then(function() {
            AnalyticsService.setProduct(this.item, this.inset.quantity);
          });
        } else if (type == "list") {
          ListService.setItem(
            this.item,
            this.list.listId,
            this.inset.quantity
          ).then(function(list) {
            for (var i = 0; i < list.wishlistItems.length; i++) {
              if (list.wishlistItems[i].itemId == this.item.itemId) {
                this.item.weight = list.wishlistItems[i].weight;
                this.item.quantity = list.wishlistItems[i].quantity;
                // this.initQuantityPerUnit();
                break;
              }
            }
          });
        } else if (type == "selfscanning") {
          SelfscanningService.setItem(
            this.item,
            parseInt(this.inset.quantity)
          ).then(function(data) {
            //this.doUpdateSelfCart(data.data);
            global.EventBus.$emit("doUpdateSelfCart", data.data);
          });
        }
      }
    },
    init() {
      if (this.item.product.leadTime) {
        this.item.product.leadDate = this.$dayjs(
          this.item.product.leadTime,
          "days"
        );
      }
      // this.initQuantityPerUnit();
    },
    canChangeMode() {
      if (
        this.manualStepperEnabled &&
        (this.item.product.productInfos.TIPOLOGIA == "Pezzo" ||
          this.item.product.productInfos.TIPOLOGIA == "Confezione") &&
        this.item.product.productInfos.QTY_LOCKED != "true"
      ) {
        return true;
      }
      return false;
    },
    changeMode(alwaysEnabled) {
      if (alwaysEnabled || this.canChangeMode()) {
        if (this.inset.showInput) {
          this.inset.showInput = false;
          this.init();
        } else {
          this.inset.showInput = true;
          this.init();
          // setTimeout(function() {
          //   var el = document.getElementById(
          //     "val-input-" + this.product.productId
          //   );
          //   el[0].select();
          // });
        }
        this.inset.quantity = this.initQuantity;
      }
    }
  },
  mounted() {
    if (this.item.product.leadTime) {
      this.item.product.leadDate = this.$dayjs(
        this.item.product.leadTime,
        "days"
      );
    }
    // this.initQuantityPerUnit();
    this.inset.quantity = this.initQuantity;
  },
  created() {
    global.EventBus.$on("initQuantityPerUnit", () => {
      this.init();
    });
  }
  // watch: {
  //   item(newValue, oldValue) {
  //     if (newValue && oldValue && newValue.itemId != oldValue.itemId) {
  //       this.init();
  //     }
  //   }
  // }
};
</script>
