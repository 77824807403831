<template>
  <v-card class="popup-card selfscanning-manual-input-popup">
    <v-card-title v-if="popup.title" class="popup-title"
      >{{ popup.title }}<span class="title-box"></span
      ><span class="subtitle-box"></span
    ></v-card-title>
    <v-card-text v-if="!popup.removeItem">
      <v-text-field
        required
        v-model="inputData.itemCode"
        v-if="inputData.step == 1"
      ></v-text-field>
      <v-text-field
        required
        v-model="inputData.itemQuantity"
        v-if="inputData.step == 2"
      ></v-text-field
      ><span>{{ $n(inputData.product, "ssuffix") }}</span>
    </v-card-text>
    <v-card-text v-if="popup.removeItem">
      <v-text-field required v-model="inputData.removeCode"></v-text-field>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="primary" depressed min-width="150" @click="submit()"
        >Annulla</v-btn
      >
      <v-btn
        v-if="!popup.removeItem"
        color="primary"
        depressed
        min-width="150"
        @click="addCode()"
        >Conferma</v-btn
      >
      <v-btn
        v-if="popup.removeItem"
        color="primary"
        depressed
        min-width="150"
        @click="removeItemByCode()"
        >Conferma</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
// import Popup from "@/components/Popup";
import SelfscanningService from "~/service/selfscanningService";
import SelfscanningCompleted from "@/components/selfscanning/SelfscanningCompleted.vue";

export default {
  name: "ManualInput",
  props: {
    popup: { type: Object, required: true }
  },
  data() {
    return {
      inputData: {
        step: 1,
        itemCode: null,
        error: null,
        removeCode: null
      }
    };
  },
  methods: {
    ...mapActions({
      setPopupMap: "app/setPopupMap"
    }),
    submit() {
      this.$emit("submit", true);
    },
    async handleScan(code, action, callback, errorCallback, additionalParams) {
      var selfScanningFunction = null;

      switch (action) {
        case "init":
          selfScanningFunction = SelfscanningService.init;
          break;
        case "add":
          selfScanningFunction = SelfscanningService.addProduct;
          break;
        case "remove":
          selfScanningFunction = SelfscanningService.deleteProduct;
          break;
        case "terminate":
          selfScanningFunction = SelfscanningService.terminate;
          break;
        case "abort":
          selfScanningFunction = SelfscanningService.abort;
          break;
      }

      if (selfScanningFunction) {
        let resultFunc = await selfScanningFunction(code, additionalParams);
        if (
          resultFunc.data.response.status == 2 &&
          selfScanningFunction == SelfscanningService.terminate
        ) {
          var popupConfig = {
            title: "Ordine Completato",
            orderId: resultFunc.data.data.orderId
          };
          await this.$dialog.show(SelfscanningCompleted, {
            popup: popupConfig
          });
        }

        global.EventBus.$emit("updateSelfCart", resultFunc.data);

        if (callback) {
          callback();
        } else {
          if (errorCallback) {
            errorCallback();
          }
        }
      }
    },
    async removeItemByCode() {
      let _this2 = this;
      this.handleScan(
        _this2.inputData.removeCode,
        "remove",
        function() {
          _this2.$emit("submit", true);
        },
        function() {
          this.inputData.error =
            "Errore nella rimozione dell'articolo dal carrello";
        }
      );
    },
    async addCode() {
      console.log(this.inputData.itemCode);
      var _this = this;
      var reCode = /^[0-9]+$/i;
      var reQuantity = /^[0-9]+([,.][0-9]+)?$/i;
      this.inputData.error = null;
      if (!_this.inputData.itemCode) {
        _this.inputData.error = "Inserire il codice dell'articolo";
      } else if (!reCode.test(_this.inputData.itemCode)) {
        _this.inputData.error = "Formato codice non corretto";
      } else if (_this.inputData.step == 1) {
        let data = await SelfscanningService.checkCode(
          "c" + _this.inputData.itemCode
        );
        if (data && data.response.status == 0) {
          _this.inputData.step = 2;
          _this.inputData.product = data.data;
          var p = document.getElementsByClassName(
            "selfscanning-manual-input-popup"
          )[0];
          p.getElementsByClassName("title-box")[0].innerText =
            "Inserisci peso/quantità per:";
          p.getElementsByClassName("subtitle-box")[0].innerText =
            data.data.name;
        } else {
          _this.inputData.error = "Codice articolo non riconosciuto";
        }
      } else if (!_this.inputData.itemQuantity) {
        _this.inputData.error = "Inserire il valore richiesto";
      } else if (!reQuantity.test(_this.inputData.itemQuantity)) {
        _this.inputData.error = "Formato valore non corretto";
      } else {
        _this.handleScan(
          "c" + _this.inputData.itemCode,
          "add",
          function() {
            _this.$emit("submit", true);
          },
          function() {
            _this.inputData.error =
              "Errore nell'aggiunta dell'articolo al carrello";
          },
          _this.inputData.itemQuantity.replace(",", ".")
        );
      }
    }
  }
};
</script>
